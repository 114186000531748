<template>
  <el-container class="container">
    <el-header class="header-wrapper">
      <div class="logo-box">
        <img src="../../assets/logo_mini.png" width="36" />
        <span class="logo-title">313FM - {{ $t('forgot.loginTitle') }}</span>
        <span class="logo-name">{{ $t('forgot.forgotPass') }}</span>
      </div>
      <div class="info-wrapper">
        <span><i class="el-icon-online-customer-service"></i>{{ $t('forgot.contactPhone') }}</span>
        <a @click="getOfficialWebsite"><i class="el-icon-home"></i>{{ $t('forgot.home') }}</a>
        <a @click="jumpLogin"><i class="el-icon-login"></i>{{ $t('forgot.login') }}</a>
        <el-dropdown class="language-box" @command="switchLanguage">
          <span> {{ showLanguage }}<i class="el-icon-arrow-down el-icon--right"></i> </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :key="langIndex" :command="langItem.value" v-for="(langItem, langIndex) of languageItemArr">{{ langItem.label }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-header>
    <el-main>
      <div class="content-wrapper">
        <div class="steps-box">
          <el-steps :active="stepNum" :align-center="true" :class="[stepNum == 3 ? 'success' : '']">
            <el-step :title="$t('forgot.verAcc')"></el-step>
            <el-step :title="$t('forgot.modPass')"></el-step>
            <el-step :title="$t('forgot.resComp')" :icon="stepNum == 3 ? 'el-icon-check' : ''"></el-step>
          </el-steps>
        </div>

        <!-- 第一步 -->
        <div v-show="stepNum == 1" class="pwd-wrapper" style="width: 65%">
          <el-form label-width="120px" :model="oneStepSubmitData" :rules="oneStepSubmitRules" ref="oneStepForm">
            <el-form-item :label="$t('forgot.configPhone')" prop="primaryphone">
              <el-input type="tel" @input="handleNumInput" :placeholder="$t('register.chinesePhoneTips')" v-model="oneStepSubmitData.primaryphone" class="areacode-box">
                <el-select v-if="!inputAreacodeShow" v-model="oneStepSubmitData.areacode" slot="prepend" placeholder="" @change="handelChangeAreacode">
                  <el-option :key="areacodeKey + areacodeItem.id" v-for="(areacodeItem, areacodeKey) in countryList" :label="areacodeItem.name" :value="areacodeItem.id"></el-option>
                  <el-option :key="areacodeItem.id" v-for="areacodeItem in inputAreacodeList" :label="areacodeItem.name" :value="areacodeItem.id"></el-option>
                  <el-option :label="$t('userinfoEditPhone.moreAreaCode')" :value="'more'"></el-option>
                </el-select>
                <el-input v-if="inputAreacodeShow" ref="countryInput" slot="prepend" v-model="countryVal" @change="handlecountryInput"></el-input>
              </el-input>
            </el-form-item>
            <el-form-item :label="$t('forgot.verCode')" prop="img_code">
              <el-col :span="17">
                <el-input :placeholder="$t('forgot.verCodeTips')" v-model="oneStepSubmitData.img_code" maxlength="4"></el-input>
              </el-col>
              <el-col :span="6" :offset="1">
                <img :src="imgsrc" @click="getImageCode" style="display: block; border: 1px solid #ccc; cursor: pointer" />
              </el-col>
            </el-form-item>
            <el-form-item :label="$t('forgot.phoneCode')" prop="code">
              <el-col :span="14">
                <el-input :placeholder="$t('forgot.phoneCodeTips')" v-model="oneStepSubmitData.code" maxlength="6"></el-input>
              </el-col>
              <el-col :span="9" :offset="1">
                <el-button @click="getCode" :disabled="oneStepSubmitData.img_code == '' || oneStepSubmitData.primaryphone == '' ? true : iscode" type="primary" style="width: 100%">
                  <template v-if="wait == 60">
                    {{ $t('register.sendCodeBtn') }}
                  </template>
                  <template v-else>
                    {{ $t('register.sendCodeBtning', { seconds: wait }) }}
                  </template>
                </el-button>
              </el-col>
            </el-form-item>
            <el-form-item label="">
              <el-button type="primary" :disabled="oneStepSubmitData.code == ''" @click="pwdFirst" style="width: 100%">{{ $t('forgot.nextStep') }}</el-button>
            </el-form-item>
          </el-form>
        </div>

        <!-- 第二步 -->
        <div v-show="stepNum == 2" class="pwd-wrapper" style="width: 80%">
          <el-form label-width="180px" :model="twoStepSubmitData" :rules="twoStepSubmitRules" ref="twoStepForm">
            <el-form-item :label="$t('forgot.newPass')" prop="password">
              <el-col :span="22">
                <el-input show-password maxlength="20" :placeholder="$t('forgot.configNewPassformat')" v-model="twoStepSubmitData.password"></el-input>
              </el-col>
              <el-col :span="1" :offset="1">
                <el-tooltip :content="$t('forgot.configNewPassformat')" placement="bottom" effect="light">
                  <i class="el-icon-question el-icon"></i>
                </el-tooltip>
              </el-col>
            </el-form-item>
            <el-form-item :label="$t('forgot.repNewPass')" prop="passwordcheck">
              <el-col :span="22">
                <el-input show-password maxlength="20" :placeholder="$t('forgot.repNewPassTips')" v-model="twoStepSubmitData.passwordcheck"></el-input>
              </el-col>
              <el-col :span="1" :offset="1">
                <el-tooltip :content="$t('forgot.repNewPassTips')" placement="bottom" effect="light">
                  <i class="el-icon-question el-icon"></i>
                </el-tooltip>
              </el-col>
            </el-form-item>
            <el-form-item label="">
              <el-col :span="22">
                <el-button type="primary" @click="pwdget" style="width: 100%">{{ $t('forgot.nextStep') }}</el-button>
              </el-col>
            </el-form-item>
          </el-form>
        </div>

        <!-- 第三步 -->
        <div v-show="stepNum == 3" class="pwd-wrapper">
          <div class="finish-title">{{ $t('forgot.modSuccessMsg') }}</div>
          <div class="finish-content">
            <i18n path="forgot.modSuccessMsgJumpMsg" :tag="false">
              <template slot="seconds">
                {{ time }}
              </template>
              <template slot="loginPage">
                <el-button @click="jumpLogin" type="text">{{ $t('forgot.loginPage') }}</el-button>
              </template>
            </i18n>
          </div>
        </div>
      </div>
    </el-main>
    <el-footer class="footer-wrapper" v-if="$store.getters.lang !== 'tw'">
      {{ $t('common.copyrightDesc') }}
    </el-footer>
  </el-container>
</template>
<script>
import index from './index'
export default index
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import './index.scss';
</style>
